import { CircularProgress, IconButton, InputAdornment, Popover, Slider, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { ChangeEventHandler, FormEventHandler, ReactNode, useCallback, useEffect, useState, MouseEvent } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import LoupeIcon from '@mui/icons-material/Loupe';
import MoreIcon from '@mui/icons-material/More';

import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';

export type LineSliderProps = {
    totalLineNumbers: number[],
    selectedLineNumber: number,
    onLineInputSubmit: (value: number) => void,
    isSearchLoading: boolean,
    onSliderChange: (event: Event, value: number | number[], activeThumb: number) => void,
    onVisibilityInputChange: (value: boolean) => void;
    lineIncrement: number,
    detailsRoute: string,
    label: string,
    isVisible: boolean,
};

export const LineSlider = ({ totalLineNumbers, selectedLineNumber, onLineInputSubmit, isSearchLoading, lineIncrement, onSliderChange, detailsRoute, label, isVisible, onVisibilityInputChange } : LineSliderProps) => {

    const [ lineNumberInput, setLineNumberInput ] = useState<number>(selectedLineNumber);

    const handleLineInputChange : ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        const value = parseInt(event.target.value);
        setLineNumberInput(value);
    };

    const handleLineInputSubmit : FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onLineInputSubmit(lineNumberInput);
    };

    const lineScale = useCallback((value: number) => totalLineNumbers[value], [totalLineNumbers]);

    const [ popoverAnchor, setPopoverAnchor ] = useState<HTMLButtonElement | null>(null);

    const handlePopoverOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setPopoverAnchor(event.currentTarget);
    }, []);

    const handlePopoverClose = useCallback(() => setPopoverAnchor(null), []);

    useEffect(() => setLineNumberInput(selectedLineNumber), [selectedLineNumber]);

    useEffect(() => console.log('total line Numbers ------->', totalLineNumbers), [totalLineNumbers]);
    return (
        <Stack>
            <Stack direction='row'>
                <form onSubmit={handleLineInputSubmit}>
                    <TextField
                        className='line-number-input'
                        size='small'
                        type='number'
                        label={label}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                {
                                    isSearchLoading ?
                                        <CircularProgress size={15}/>
                                        :
                                        <IconButton type='submit'>
                                            <SearchIcon/>
                                        </IconButton>
                                }

                            </InputAdornment>,
                            sx: {
                                width: '150px'
                            }
                        }}
                        value={lineNumberInput}
                        onChange={handleLineInputChange}
                        disabled={totalLineNumbers.length === 0 || isSearchLoading || !isVisible}
                    />
                </form>
                <Tooltip placement='top'
                    title = {
                        <Stack>
                            <LineInfoText>Click to see more options related to the line</LineInfoText>
                        </Stack>
                    }
                >
                    <IconButton onClick={handlePopoverOpen} className='line-details-button'>
                        <MoreIcon/>
                    </IconButton>
                </Tooltip>
                <Popover
                    open={!!popoverAnchor}
                    anchorEl={popoverAnchor}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical:'top',
                        horizontal: 'right'
                    }}
                >
                    <Stack spacing={2}>
                        <Tooltip placement='left'
                            title = {
                                <Stack>
                                    <LineInfoText>Click to show or hide the selected type of line</LineInfoText>
                                </Stack>
                            }
                        >
                            <Switch
                                checked={isVisible}
                                onChange={(event, checked) => {
                                    onVisibilityInputChange(checked);
                                }}
                                disabled={isSearchLoading}
                            />
                        </Tooltip>
                        <Tooltip placement='left'
                            title={
                                <Stack spacing={0.5}>
                                    <LineInfoText>Start: {totalLineNumbers[0]}</LineInfoText>
                                    <LineInfoText>End: {totalLineNumbers[totalLineNumbers.length - 1]}</LineInfoText>
                                    <LineInfoText>Increment: {lineIncrement} </LineInfoText>
                                </Stack>
                            }
                        >
                            <IconButton>
                                <InfoIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            placement='left'
                            title={
                                <Stack>
                                    <LineInfoText>Click to open the selected line in the details using the 2D Viewer</LineInfoText>
                                </Stack>
                            }
                        >
                            <IconButton href={detailsRoute} target='_blank' disabled={isSearchLoading}>
                                <LoupeIcon/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Popover>
            </Stack>

            <Slider
                className='line-slider'
                value={selectedLineNumber === 0? 0 : totalLineNumbers.indexOf(selectedLineNumber)}
                aria-label='Inline'
                defaultValue={0}
                step={1}
                min={0}
                max={totalLineNumbers.length - 1}
                valueLabelDisplay='off'
                onChange={onSliderChange}
                disabled={totalLineNumbers.length === 0 || !isVisible}
                scale={lineScale}
            />
        </Stack>
    );
};

type LineInfoTextProps = {
    children: ReactNode
};

const LineInfoText = ({ children } : LineInfoTextProps) => (<Typography variant='caption'  color='white'>{children}</Typography>);