import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { useMetadata3DViewer } from '../api/useVolume';
import { LineType } from '../models/enums/LineType';
import { IMetadata3DViewerRequest } from '../models/interfaces/requests/IMetadata3DViewerRequest';
import { use3DGridStore } from '../stores/use3DGridStore';
import { use3DSceneStore } from '../stores/use3DSceneStore';
import { use3DViewerStore } from '../stores/use3DViewerStore';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';
import { getDeeperRelativeLinesToLoad, getRealLineValue, getRelativeLinesToLoadInFirstIteration } from '../utils/TileUtils';
import { VolumeType } from '../models/enums/VolumeType';
import { Line2D } from './Line2D';
import { getCountTimeDepth } from '../threejs/utils/ScaleUtils';
import { Lines3D2 } from './Lines3D2';
import { Zslice3D } from './Zslice3D';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';
import { use3DFeatureFlags } from '../hooks/useSeismic3DFeatureFlags';
import { SampleIntervalUnit } from '../models/enums/SampleIntervalUnit';
import { MeasurementType } from 'features/seismic/models/enums/MeasurementType';

export type Grid3DProps = {
    onLoadingProgressChange: (progress: number) => void
    volumeIdentifier: string
};

export const Grid3D = ({ onLoadingProgressChange, volumeIdentifier } : Grid3DProps) => {
    const geopostScene = use3DSceneStore(state => state.scene);
    const mainFeatureSrid = use3DViewerStore(state => state.mainFeatureSrid);

    const setHeightPixelFactor = use3DViewerStore(state => state.setHeightPixelFactor);
    const setCountTimeDepth = use3DViewerStore(state => state.setCountTimeDepth);

    const gridSelectedSeismic = use3DGridStore(state => state.gridSelectedSeismic);

    const currentDeepeningIterationRef = useRef(-1);

    const {
        divisionFactor,
        changingDivisionFactor,
        screenHeightFactor,
        tileWidth,
        skipInlineFactor,
        skipXlineFactor,
        initialDeepeningRange,
        deepeningsTotal,
        totalZsliceNumbers,
        setSearchedInlineNumber,
        setSearchedXlineNumber,
        setSearchedZsliceNumber,
        setTotalInlineNumbers,
        setTotalXlineNumbers,
        setTotalZsliceNumbers,
        setSelectedInlineNumber,
        setSelectedXlineNumber,
        setSelectedSeismic3DInfo,
        setIsXLineSearchLoading,
        setIsInlineSearchLoading,
    } = use3DGridStore(state => ({
        divisionFactor: state.divisionFactor,
        changingDivisionFactor: state.changingDivisionFactor,
        screenHeightFactor: state.screenHeightFactor,
        tileWidth: state.tileWidth,
        skipInlineFactor: state.skipInlineFactor,
        skipXlineFactor: state.skipXlineFactor,
        initialDeepeningRange: state.initialLoadingRange,
        deepeningsTotal: state.totalLoadingIterations,
        totalZsliceNumbers: state.totalZsliceNumbers,
        setSearchedInlineNumber: state.setSearchedInlineNumber,
        setSearchedXlineNumber: state.setSearchedXlineNumber,
        setTotalInlineNumbers: state.setTotalInlineNumbers,
        setTotalXlineNumbers: state.setTotalXlineNumbers,
        setTotalZsliceNumbers: state.setTotalZsliceNumbers,
        setSelectedInlineNumber: state.setSelectedInlineNumber,
        setSelectedXlineNumber: state.setSelectedXlineNumber,
        setSelectedSeismic3DInfo: state.setSelectedSeismic3DInfo,
        setIsXLineSearchLoading: state.setIsXLineSearchLoading,
        setIsInlineSearchLoading: state.setIsInlineSearchLoading,
        setSearchedZsliceNumber: state.setSearchedZsliceNumber
    }), shallow);

    const searchedInlineNumber = use3DGridStore(state => state.searchedInlineNumber);
    const searchedXlineNumber = use3DGridStore(state => state.searchedXlineNumber);

    const [ gridLoadingError, setGridLoadingError ] = useState<boolean>(false);

    const [metadata3DViewerRequest, setMetadata3DViewerRequest] = useState<IMetadata3DViewerRequest>();
    const { data: metadata3DViewer, isError: metadata3DViewerError } = useMetadata3DViewer(metadata3DViewerRequest);
    const survey3DInfo = metadata3DViewer?.Metadata.Survey3DInfo;
    const entitlementLimits = metadata3DViewer?.Metadata.Entitlement3DLimits;

    const [ inlineRelativeNumbersToLoad, setInlineRelativeNumbersToLoad ] = useState<number[]>([]);

    const totalOfDeepeningIterations = useMemo(() => getUrlParams().totalLoadingIterations, []);

    const lineLoadingStepRef = useRef<number>(getUrlParams().lineLoadingStep);

    const featureFlags = use3DFeatureFlags();

    const inlineNumbersToLoad = useMemo(() => {
        if (survey3DInfo && entitlementLimits) {
            return inlineRelativeNumbersToLoad.map(relativeNumber => getRealLineValue(relativeNumber, survey3DInfo.InlineStart, survey3DInfo.InlineIncrement, entitlementLimits.InlineStart - survey3DInfo.InlineStart));
        } else {
            return [];
        }
    }, [entitlementLimits, inlineRelativeNumbersToLoad, survey3DInfo]);

    const [ geometryLoadedInlinesNumbers, setGeometryLoadedInlineNumbers ] = useState<number[]>([]);

    const [ imageLoadedInlinesTotal, setImageLoadedInlinesTotal ] = useState<number>(0);

    const [ inlineNumbersWithError, setInlineNumbersWithError ] = useState<number[]>([]);
    const [ xlineNumbersWithError, setXlineNumbersWithError ] = useState<number[]>([]);

    const [ geometryLoadedXlinesNumbers, setGeometryLoadedXlineNumbers ] = useState<number[]>([]);

    const [ imageLoadedXlinesTotal, setImageLoadedXlinesTotal ] = useState<number>(0);

    const [ imageLoadedZslicesTotal, setImageLoadedZslicesTotal ] = useState(0);

    const [ xlineRelativeNumbersToLoad, setXlineRelativeNumbersToLoad ] = useState<number[]>([]);

    const [ zsliceRelativeNumbersToLoad, setZsliceRelativeNumbersToLoad ] = useState<number[]>([]);

    const [ sampleInterval, samplesPerTrace ] = useMemo(() => {
        if (!metadata3DViewer) {
            return [null, null];
        } else {
            const sampleIntervalUnit = metadata3DViewer.Metadata.Header.SampleIntervalUnit;
            return [sampleIntervalUnit === SampleIntervalUnit.Milisecond ? metadata3DViewer.Metadata.Header.SampleInterval / 1000 : metadata3DViewer.Metadata.Header.SampleInterval,
                metadata3DViewer.Metadata.Header.SamplesPerTrace];
        }
    }, [metadata3DViewer]);

    const colorbar = useMemo(() => {
        if (metadata3DViewer?.Metadata.MeasurementType === MeasurementType.Velocity) {
            return 'jet';
        }
        else {
            return 'binary';
        }
    }, [metadata3DViewer]);

    const zsliceNumbersToLoad = useMemo(() => {
        if (!sampleInterval) {
            return [];
        } else {
            return zsliceRelativeNumbersToLoad.map(relativeNumber => relativeNumber * sampleInterval);
        }
    }, [sampleInterval, zsliceRelativeNumbersToLoad]);

    const mainFeatureGeomData = use3DViewerStore(state => state.mainFeatureGeomData);
    const previousTotalZsliceNumbersRef = useRef<number[]>([]);

    const volumeType = useMemo<SurveyType>(() => metadata3DViewer?.Metadata.Type ?? SurveyType.Seismic3D, [metadata3DViewer]);

    const xlineNumbersToLoad = useMemo(() => {
        if (survey3DInfo && entitlementLimits) {
            return xlineRelativeNumbersToLoad.map(relativeNumber => getRealLineValue(relativeNumber, survey3DInfo.XlineStart, survey3DInfo.XlineIncrement, entitlementLimits.XlineStart - survey3DInfo.XlineStart ));
        } else {
            return [];
        }
    }, [entitlementLimits, survey3DInfo, xlineRelativeNumbersToLoad]);

    const startFirstDeepeningIteration = useCallback(() => {
        if (!entitlementLimits || !featureFlags) {
            return;
        }
        const inlineRelativeNumbers = getRelativeLinesToLoadInFirstIteration(initialDeepeningRange, entitlementLimits?.TotalInlines);
        const xlineRelativeNumbers = getRelativeLinesToLoadInFirstIteration(initialDeepeningRange, entitlementLimits?.TotalXlines);
        setInlineRelativeNumbersToLoad(inlineRelativeNumbers);
        setXlineRelativeNumbersToLoad(xlineRelativeNumbers);

        currentDeepeningIterationRef.current = 1;
    }, [entitlementLimits, featureFlags, initialDeepeningRange]);

    useEffect(() => setTotalZsliceNumbers(zsliceNumbersToLoad), [zsliceNumbersToLoad]);

    const increaseDeepeningIteration = useCallback(() => {
        currentDeepeningIterationRef.current += 1;

        if (currentDeepeningIterationRef.current > totalOfDeepeningIterations) {
            return;
        }
        onLoadingProgressChange(0);

        const updatedInlineNumbers = getDeeperRelativeLinesToLoad(inlineRelativeNumbersToLoad);
        const updatedXlineNumbers = getDeeperRelativeLinesToLoad(xlineRelativeNumbersToLoad);
        setInlineRelativeNumbersToLoad(updatedInlineNumbers);
        setXlineRelativeNumbersToLoad(updatedXlineNumbers);

        if (featureFlags?.enableZslice) {
            const updatedZsliceNumbers = getDeeperRelativeLinesToLoad(zsliceRelativeNumbersToLoad);
            setZsliceRelativeNumbersToLoad(updatedZsliceNumbers);
        }
    }, [totalOfDeepeningIterations, inlineRelativeNumbersToLoad, xlineRelativeNumbersToLoad, featureFlags?.enableZslice, zsliceRelativeNumbersToLoad]);

    const handleInlineError = (inlineNumbers: number[]) => setInlineNumbersWithError(current => [...current, ...inlineNumbers]);
    const handleXlineError = (xlineNumbers: number[]) => setXlineNumbersWithError(current => [...current, ...xlineNumbers]);

    useEffect(() => {
        if (gridSelectedSeismic) {
            setSearchedInlineNumber(null);
            setSearchedXlineNumber(null);
            //onLoadStart();
            onLoadingProgressChange(0);
            setMetadata3DViewerRequest({
                volumeToken: gridSelectedSeismic.volumeToken,
                volumeIdentifier: volumeIdentifier,
                factorDivide: divisionFactor,
                factorToChangeDivide: changingDivisionFactor,
                screenHeight: screenHeightFactor,
                tileWidth: tileWidth,
                skipInline: skipInlineFactor,
                skipXLine: skipXlineFactor,
                includePercentageCalc: true
            });
            geopostScene?.addGrid(gridSelectedSeismic.volumeToken);
            setHeightPixelFactor(gridSelectedSeismic.samplesPerTrace);
            setCountTimeDepth(getCountTimeDepth(gridSelectedSeismic.sampleInterval, gridSelectedSeismic.sampleIntervalUnit, gridSelectedSeismic.samplesPerTrace));
        }
    }, [gridSelectedSeismic, geopostScene]);

    useEffect(() => {
        if (!!metadata3DViewer && !!featureFlags) {
            setGridLoadingError(false);
            startFirstDeepeningIteration();
            setSelectedSeismic3DInfo(metadata3DViewer.Metadata.Survey3DInfo);
        }
    }, [metadata3DViewer, featureFlags]);

    useEffect(() => {
        if (metadata3DViewerError) {
            onLoadingProgressChange(100);
        }
        setGridLoadingError(metadata3DViewerError);
    }, [metadata3DViewerError]);

    useEffect(() => {
        const imageToLoadLinesTotal = inlineNumbersToLoad.length + xlineNumbersToLoad.length + zsliceNumbersToLoad.length;
        const loadedImageLinesTotal = imageLoadedInlinesTotal + imageLoadedXlinesTotal + inlineNumbersWithError.length + xlineNumbersWithError.length + imageLoadedZslicesTotal;
        if (loadedImageLinesTotal !== 0) {
            onLoadingProgressChange(loadedImageLinesTotal/imageToLoadLinesTotal * 100);
        }

        console.log('=============================== LOADING PROGRESS =========================', loadedImageLinesTotal + '/' + imageToLoadLinesTotal, [imageLoadedInlinesTotal, imageLoadedXlinesTotal, imageLoadedZslicesTotal]);
        if (imageToLoadLinesTotal > 0 && loadedImageLinesTotal === (imageToLoadLinesTotal)) {
            increaseDeepeningIteration();
        }
    }, [imageLoadedInlinesTotal, imageLoadedXlinesTotal, inlineNumbersWithError, xlineNumbersWithError, imageLoadedZslicesTotal]);

    useEffect(() => {
        if (geometryLoadedInlinesNumbers.length + inlineNumbersWithError.length === inlineNumbersToLoad.length &&
            geometryLoadedXlinesNumbers.length + xlineNumbersWithError.length === xlineNumbersToLoad.length
            && (geometryLoadedInlinesNumbers.length > 0 || geometryLoadedXlinesNumbers.length > 0)) {
            //onLoadEnd();

            if (currentDeepeningIterationRef.current === 1) {
                selectCentralLines();

                if (featureFlags?.enableZslice && !!samplesPerTrace) {
                    const zsliceRelativeNumbers = getRelativeLinesToLoadInFirstIteration(initialDeepeningRange, samplesPerTrace);
                    setZsliceRelativeNumbersToLoad(zsliceRelativeNumbers);

                }

                onLoadingProgressChange(1);
            }
        }
    }, [geometryLoadedXlinesNumbers, geometryLoadedInlinesNumbers, inlineNumbersWithError, xlineNumbersWithError]);

    useEffect(() => {
        setTotalInlineNumbers(geometryLoadedInlinesNumbers);
        setTotalXlineNumbers(geometryLoadedXlinesNumbers);
        //setTotalZsliceNumbers(zsliceNumbersToLoad);
    }, [geometryLoadedXlinesNumbers, geometryLoadedInlinesNumbers]);

    const selectCentralLines = () => {
        const centralInlineIndex = Math.round(geometryLoadedInlinesNumbers.length / 2);
        const centralInlineNumber = geometryLoadedInlinesNumbers[centralInlineIndex];
        setSearchedInlineNumber(centralInlineNumber);

        const centralXlineIndex = Math.round(geometryLoadedXlinesNumbers.length / 2);
        const centralXlineNumber = geometryLoadedXlinesNumbers[centralXlineIndex];
        setSearchedXlineNumber(centralXlineNumber);
    };

    const [ isInlineVisible, isXlineVisible, isZsliceVisible ] = use3DGridStore(state => [state.isInlineVisible, state.isXlineVisible, state.isZsliceVisible]);

    useEffect(() => {
        if (currentDeepeningIterationRef.current === 1 && totalZsliceNumbers.length > 0 && previousTotalZsliceNumbersRef.current.length === 0) {
            const centralZsliceIndex = Math.round(zsliceNumbersToLoad.length / 2);
            const centralZsliceNumber = zsliceNumbersToLoad[centralZsliceIndex];
            setSearchedZsliceNumber(centralZsliceNumber);
        }
        previousTotalZsliceNumbersRef.current = totalZsliceNumbers;
    }, [totalZsliceNumbers]);

    return (
        <React.Fragment>
            {
                geopostScene && metadata3DViewer
                &&
                <React.Fragment>
                    {
                        (
                            volumeType === SurveyType.Seismic3D ?
                                <React.Fragment>
                                    {/*<Lines3D
                                        lineLoadingStep={lineLoadingStepRef.current}
                                        seismicMetadata={metadata3DViewer}
                                        lineType={LineType.Inline}
                                        scene={geopostScene}
                                        searchedLineNumber={searchedInlineNumber}
                                        srid={mainFeatureSrid}
                                        setSelectedLineNumber={setSelectedInlineNumber}
                                        setIsPriorityLineLoading={setIsInlineSearchLoading}
                                        onTotalImageLoadedLinesChange={setImageLoadedInlinesTotal}
                                        onLoadedLineNumbersChange={setGeometryLoadedInlineNumbers}
                                        lineNumbersToLoad={inlineNumbersToLoad}
                                        onLoadingLineError={handleInlineError}
                                    ></Lines3D>
                                    <Lines3D
                                        lineLoadingStep={lineLoadingStepRef.current}
                                        seismicMetadata={metadata3DViewer}
                                        lineType={LineType.Xline}
                                        scene={geopostScene}
                                        searchedLineNumber={searchedXlineNumber}
                                        srid={mainFeatureSrid}
                                        setSelectedLineNumber={setSelectedXlineNumber}
                                        setIsPriorityLineLoading={setIsXLineSearchLoading}
                                        onTotalImageLoadedLinesChange={setImageLoadedXlinesTotal}
                                        onLoadedLineNumbersChange={setGeometryLoadedXlineNumbers}
                                        lineNumbersToLoad={xlineNumbersToLoad}
                                        onLoadingLineError={handleXlineError}
                                    ></Lines3D>*/}
                                    <Lines3D2
                                        lineLoadingStep={lineLoadingStepRef.current}
                                        seismicMetadata={metadata3DViewer.Metadata}
                                        lineType={LineType.Inline}
                                        scene={geopostScene}
                                        searchedLineNumber={searchedInlineNumber}
                                        srid={mainFeatureSrid}
                                        setSelectedLineNumber={setSelectedInlineNumber}
                                        setIsPriorityLineLoading={setIsInlineSearchLoading}
                                        onTotalImageLoadedLinesChange={setImageLoadedInlinesTotal}
                                        onLoadedLineNumbersChange={setGeometryLoadedInlineNumbers}
                                        lineNumbersToLoad={inlineNumbersToLoad}
                                        onLoadingLineError={handleInlineError}
                                        isVisible={isInlineVisible}
                                        colorbar={colorbar}
                                    />
                                    <Lines3D2
                                        lineLoadingStep={lineLoadingStepRef.current}
                                        seismicMetadata={metadata3DViewer.Metadata}
                                        lineType={LineType.Xline}
                                        scene={geopostScene}
                                        searchedLineNumber={searchedXlineNumber}
                                        srid={mainFeatureSrid}
                                        setSelectedLineNumber={setSelectedXlineNumber}
                                        setIsPriorityLineLoading={setIsXLineSearchLoading}
                                        onTotalImageLoadedLinesChange={setImageLoadedXlinesTotal}
                                        onLoadedLineNumbersChange={setGeometryLoadedXlineNumbers}
                                        lineNumbersToLoad={xlineNumbersToLoad}
                                        onLoadingLineError={handleXlineError}
                                        isVisible={isXlineVisible}
                                        colorbar={colorbar}
                                    />
                                    {
                                        featureFlags?.enableZslice
                                        &&
                                        <Zslice3D
                                            isVisible={isZsliceVisible}
                                            surveyMetadata={metadata3DViewer.Metadata}
                                            sliceNumbersToLoad={zsliceNumbersToLoad}
                                            onTotalSlicesLoadedChange={setImageLoadedZslicesTotal}
                                            colorbar={colorbar}
                                        />
                                    }
                                </React.Fragment>
                                :
                                (
                                    metadata3DViewer &&
                                    <Line2D onLoadEnd={() => onLoadingProgressChange(100)}  scene={geopostScene} seismicMetadata={metadata3DViewer.Metadata} srid={mainFeatureSrid} colorbar={metadata3DViewer.Colorbar}/>
                                )
                        )
                    }
                </React.Fragment>
            }
        </React.Fragment>);
};
