import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Scene } from 'three';

import { LineType } from 'features/seismic/models/enums/LineType';
import { use3DSceneStore } from '../stores/use3DSceneStore';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';

export type DimensionIndex = {
    totalX: number,
    totalY: number,
    indexNumber: number
};

export type Dimension3DProps = {
    selectedIndexNumber: number | null,
    indexesToLoad: DimensionIndex[],
    priorityIndexToLoad: DimensionIndex | null,
    loadTile: (index: number, xStart: number, xEnd: number, yStart: number, yEnd: number) => Promise<Blob | null>,
    onLoadTile: (tile: Blob, indexNumber: number, xStart: number, xEnd: number, yStart: number, yEnd: number) => void,
    onLoadIndex: (indexNumber: number) => void,
    tileHeight: number,
    tileWidth: number,
    onSelectIndex: (indexNumber: number) => void,
    lineType: LineType,
    isVisible: boolean,
    onIndexLoading: (indexNumber: number) => void
};

export const Dimension3D = ( { selectedIndexNumber, indexesToLoad, loadTile, onLoadIndex, onLoadTile, tileHeight, tileWidth, onSelectIndex, lineType, priorityIndexToLoad, isVisible, onIndexLoading } : Dimension3DProps ) => {
    const { current: lineLoadingStep } = useRef(getUrlParams().lineLoadingStep);
    const indexesToLoadQueue = useRef<DimensionIndex[]>([]);
    const { current: loadedIndexes } = useRef<DimensionIndex[]>([]);

    const geopostScene = use3DSceneStore(state => state.scene);

    const previousSelectedIndexNumberRef = useRef<number | null>(null);

    const startLoadingIndexes = () => {
        sortIndexesFromTheMiddle(indexesToLoadQueue.current);
        //setLoadedIndexes([]);
        for (let i = 0; i < 1; i++) {
            loadTilesFromIndexesQueue();
        }
    };

    const sortIndexesFromTheMiddle = useCallback((lines: DimensionIndex[]) => {
        lines.sort((a, b) => a.indexNumber - b.indexNumber);
        const middleIndex = Math.round(lines.length / 2);
        const pivot = lines[middleIndex];
        lines.sort((a, b) => Math.abs(a.indexNumber - pivot.indexNumber) - Math.abs(b.indexNumber - pivot.indexNumber) || b.indexNumber - a.indexNumber);
    }, []);

    useEffect(() => {
        const newIndexesToLoad = indexesToLoad.filter(index => !loadedIndexes.find(loadedIndex => loadedIndex.indexNumber === index.indexNumber));
        if (newIndexesToLoad.length > 0) {
            indexesToLoadQueue.current.push(...newIndexesToLoad);
            startLoadingIndexes();
        }
    }, [indexesToLoad, geopostScene]);

    const loadTilesFromIndexesQueue = async () => {
        const dimensionIndex = indexesToLoadQueue.current.shift();

        if (!dimensionIndex) {
            return;
        }

        loadTilesFromIndex(dimensionIndex, loadTilesFromIndexesQueue);
    };

    const loadTilesFromIndex = async (dimensionIndex: DimensionIndex, onLoadEnd = () => {}) => {

        const tilesMatrixColumns = Math.ceil(dimensionIndex.totalX / tileWidth);
        const tilesMatrixRows = Math.ceil(dimensionIndex.totalY / tileHeight);
        const tilePromises : Promise<Blob | null>[] = [];
        const tiles : (Blob | null)[][] = Array.from({ length: tilesMatrixColumns }, () => Array.from({ length: tilesMatrixRows }, () => null));
        const xUnitsPerTile = Math.ceil((dimensionIndex.totalX / tilesMatrixColumns));
        const yUnitsPerTile = Math.ceil((dimensionIndex.totalY / tilesMatrixRows));
        if (dimensionIndex.indexNumber === 12154) {
            debugger;
        }
        for (let i = 0; i < tilesMatrixColumns; i++) {
            for (let j = 0; j < tilesMatrixRows; j++) {
                const minX = i * xUnitsPerTile;
                const minY = j * yUnitsPerTile;
                const maxX = minX + xUnitsPerTile;
                const maxY = minY + yUnitsPerTile;

                const indexNumber = dimensionIndex.indexNumber;
                if (dimensionIndex.indexNumber === 4254) {
                    debugger;
                }
                const promise = loadTile(indexNumber, minX, maxX, minY, maxY);
                onIndexLoading(indexNumber);
                promise.then(tile => {
                    if (!!tile) {
                        const tileXIndex = i;
                        const tileYIndex = j;
                        if (dimensionIndex.indexNumber === 4254) {
                            debugger;
                        }
                        onLoadTile(tile, indexNumber, tileXIndex, maxX, tileYIndex, maxY);
                    }
                    tiles[i][j] = tile;
                });
                tilePromises.push(promise);
            }
        }

        await Promise.allSettled(tilePromises).then(() => {
            loadedIndexes.push(dimensionIndex);
            onLoadIndex(dimensionIndex.indexNumber);
            onLoadEnd();
        });
    };

    useEffect(() => {
        if (!selectedIndexNumber && selectedIndexNumber !== 0) {
            return;
        }
        const searchedIndexNumber = selectedIndexNumber;
        if (!!loadedIndexes.find(loadedIndex => loadedIndex.indexNumber === searchedIndexNumber) || !!indexesToLoad.find(index => index.indexNumber === searchedIndexNumber || priorityIndexToLoad?.indexNumber === searchedIndexNumber)) {
            geopostScene?.changeTiledDimensionVisibility(searchedIndexNumber, lineType, true);
            onSelectIndex(searchedIndexNumber);
        }
        if ((!!previousSelectedIndexNumberRef.current || previousSelectedIndexNumberRef.current === 0)  && previousSelectedIndexNumberRef.current !== searchedIndexNumber) {
            geopostScene?.changeTiledDimensionVisibility(previousSelectedIndexNumberRef.current, lineType, false);
        }
        previousSelectedIndexNumberRef.current = searchedIndexNumber;
    }, [selectedIndexNumber]);

    useEffect(() => {
        if (!!priorityIndexToLoad) {
            loadTilesFromIndex(priorityIndexToLoad, () => {
                onLoadIndex(priorityIndexToLoad.indexNumber);
            });
        }
    }, [priorityIndexToLoad]);

    useEffect(() => {
        if (!!selectedIndexNumber) {
            geopostScene?.changeTiledDimensionVisibility(selectedIndexNumber, lineType, isVisible);
        }
    }, [isVisible]);

    return (<Fragment></Fragment>);
};