import * as THREE from 'three';
import { Point } from 'ol/geom';
import { Material } from 'three';

import { buildLineByTraces, buildMainMeshByGeomWKT, buildMeshByGeomWKT, getOlGeometryFromWKT } from 'features/seismic-3d/components/projections/featureGeomPolygon';
import { lineMetadata } from 'features/seismic-3d/models/types/LineMetadata';
import { GeopostScene } from './GeopostScene';
import { MeshData } from 'features/seismic-3d/models/types/MeshData';
import { Survey3DInfo } from 'features/seismic-3d/models/types/Survey3DInfo';
import { LineType } from 'features/seismic/models/enums/LineType';
import { getTotalOfTilesFromLine } from 'features/seismic-3d/utils/TileUtils';

export class GeopostTraceBasedScene extends GeopostScene {
    private scaleFactor: number = 1/80;
    private survey3DInfo: Survey3DInfo;
    public constructor(survey3DInfo: Survey3DInfo) {
        super();
        this.survey3DInfo = survey3DInfo;
    }

    public override setInitialCameraPosition = () => {
        this.initialCameraPosition.y = -15.581912136570203;
        this.initialCameraPosition.x = 74.42524432309011;
        this.initialCameraPosition.z = 62.624026061265845;

        this.initialCameraRotation.x = -0.07192964802809282;
        this.initialCameraRotation.y = 0.4206674684837491;
        this.initialCameraRotation.z = 0.033481421362511414;
        this.initialCameraRotation.w = 0.9037389009650822;
    };

    public override setCamera(canvasWidth: number, canvasHeight: number){
        this.camera = new THREE.PerspectiveCamera(75, canvasWidth / canvasHeight, 1, 1000);
        //this.camera.rotateY(6);
        this.resetCamera();
        //this.camera.zoom = 50;
    }

    public addGridSection = (linesData: lineMetadata[], volumeToken: string) => {
        const grid = this.grid;
        if (grid) {
            linesData.forEach((lineData) => {
                const lineGroup = new THREE.Group();
                lineGroup.name = this.getLineGroupName(lineData.lineNumber, lineData.lineType);
                //const lineMesh = new GeopostLineMesh(lineData.geometryMetadata.TotalTraces, lineData.geometryMetadata.Line, '#d9d9d9', lineData.lineType, lineData.lineNumber);//buildMeshByGeomWKT(lineData.geometryMetadata.Line, null, '#d9d9d9');
                //lineMesh.name = 'line-mesh';
                const lineMesh = buildLineByTraces(lineData.geometryMetadata.Min, lineData.geometryMetadata.Max, lineData.lineNumber, lineData.lineType, '#d9d9d9', this.survey3DInfo, this.scaleFactor);
                const lineMaterial = lineMesh.material;

                if (lineMaterial instanceof Material) {
                    lineMaterial.transparent = true;
                    lineMesh.name = 'line-mesh';
                    lineGroup.add(lineMesh);

                    //lineGroup.position.set(lineMesh.position.x, lineMesh.position.y, lineMesh.position.z);
                    grid.add(lineGroup);

                }
            });
            grid.position.set(0, 0, 0);
        }
    };

    public addMainMesh = ({geomWKT, centroidWKT, meshColor, meshToken} : MeshData) => {
        const mesh = new THREE.Line(new THREE.BufferGeometry(), new THREE.LineBasicMaterial());
        const centerX = (this.survey3DInfo.InlineEnd - this.survey3DInfo.InlineStart) / this.survey3DInfo.InlineIncrement / 2 * this.scaleFactor;
        const centerZ = (this.survey3DInfo.XlineEnd - this.survey3DInfo.XlineStart) / this.survey3DInfo.XlineIncrement / 2 * this.scaleFactor;
        //this.camera?.position.set(centerX, -5, centerZ);
        return mesh;
    };

    public addLineTiles = (line: lineMetadata, samplesPerTrace: number, tileWidth: number) => {

        /*if (line.lineNumber === 2875) {
            debugger;
        }*/

        const lineIncrement = line.lineType === LineType.Inline ? this.survey3DInfo.XlineIncrement : this.survey3DInfo.InlineIncrement;

        const lineFirstX = (
            line.lineType === LineType.Inline ?
                (line.lineNumber - this.survey3DInfo.InlineStart) / this.survey3DInfo.InlineIncrement
                :
                (line.geometryMetadata.Min - this.survey3DInfo.InlineStart) / this.survey3DInfo.InlineIncrement
        ) * this.scaleFactor;

        const lineFinalX = (
            line.lineType === LineType.Inline ?
                (line.lineNumber - this.survey3DInfo.InlineStart) / this.survey3DInfo.InlineIncrement
                :
                (line.geometryMetadata.Max - this.survey3DInfo.InlineStart) / this.survey3DInfo.InlineIncrement
        ) * this.scaleFactor;

        const lineFirstY = (
            line.lineType === LineType.Inline ?
                (line.geometryMetadata.Min - this.survey3DInfo.XlineStart) / this.survey3DInfo.XlineIncrement
                :
                (line.lineNumber - this.survey3DInfo.XlineStart) / this.survey3DInfo.XlineIncrement
        ) * this.scaleFactor;

        const lineFinalY = (
            line.lineType === LineType.Inline ?
                (line.geometryMetadata.Max - this.survey3DInfo.XlineStart) / this.survey3DInfo.XlineIncrement
                :
                (line.lineNumber - this.survey3DInfo.XlineStart) / this.survey3DInfo.XlineIncrement
        ) * this.scaleFactor;

        const lineFirstCoordinate : [number, number] = [lineFirstX, lineFirstY];
        const lineLastCoordinate : [number, number] = [lineFinalX, lineFinalY];
        const tileHeight = samplesPerTrace * this.scaleFactor;

        const lineTracesNumber = (line.geometryMetadata.Max - line.geometryMetadata.Min) / lineIncrement;
        this.addTileGroup(line.lineNumber, line.lineType, getTotalOfTilesFromLine(lineTracesNumber, tileWidth), lineFirstCoordinate, lineLastCoordinate, tileHeight);
    };

    public addZSliceTilesByDepth = (totalInlines: number, totalXlines: number, inlineStart: number, xlineStart: number, depth: number, sampleInterval: number, tileWidth: number, tileHeight: number) => {
        const tilesMatrixColumns = Math.ceil(totalInlines / tileWidth);
        const tilesMatrixRows = Math.ceil(totalXlines / tileHeight);

        const scaledDepth = depth / sampleInterval * this.scaleFactor * -1;

        const depthGroup = new THREE.Group();
        depthGroup.name = this.getTileGroupName(depth, LineType.ZSlice);

        const xUnitsPerTile = Math.ceil(totalInlines / tilesMatrixColumns);
        const yUnitsPerTile = Math.ceil(totalXlines / tilesMatrixRows);

        for (let i = 0; i < tilesMatrixColumns; i += 1) {
            for (let j = 0; j < tilesMatrixRows; j += 1) {
                const minX = i * xUnitsPerTile + inlineStart;
                const minZ = j * yUnitsPerTile + xlineStart;
                const planeWidth = xUnitsPerTile; //(i + 1 < tilesMatrixColumns ? xUnitsPerTile : totalInlines % xUnitsPerTile);
                const planeHeight = yUnitsPerTile; //(j + 1 < tilesMatrixRows ? yUnitsPerTile : totalInlines % yUnitsPerTile);

                const geometry = new THREE.PlaneGeometry(planeWidth, planeHeight);
                const  material = new THREE.MeshPhongMaterial({color: '#cccccc', side: THREE.DoubleSide, transparent: true, opacity: 0.4});
                const mesh = new THREE.Mesh(geometry, material);
                mesh.position.set(minX + (planeWidth / 2), scaledDepth, minZ + (planeHeight / 2));
                mesh.name = this.getTileObjectName(i, depth, LineType.ZSlice, j);
                mesh.visible = true;
                mesh.rotation.set(-Math.PI/2, 0, 0);
                depthGroup.add(mesh);
            }
        }

        depthGroup.visible = false;
        depthGroup.scale.setX(this.scaleFactor);
        depthGroup.scale.setZ(this.scaleFactor);
        this.add(depthGroup);
    };
}