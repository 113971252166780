import { css, IconButton, Tooltip } from '@mui/material';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import { getParamsFromUrl } from '../utils/seismicUrlUtils';
import { useSessionStore } from 'session/useSessionStore';

const style = css({
    '& a': {
        color: '#FFF'
    },
    '& a:hover': {
        color: '#FFF'
    }
});

export const LinkTo3DViewer = () => {
    const { current: { volumeToken, volumeIdentifier } } = useRef(getParamsFromUrl());

    const path = useMemo(() => {
        return `/3dviewer?type=volume&${ volumeIdentifier === 'volumeId' ? 'volumeId=' + volumeToken : 'volumeToken=' + volumeToken  }`;
    }, [volumeIdentifier, volumeToken]);
    return (
        <IconButton id='open-on-3d-viewer-btn' color='primary' css={style} title='Open volume on 3D Viewer'><Link style={{display: 'flex', alignItems: 'center'}} to={path} target='_blank'><ViewInArIcon/></Link></IconButton>
    );
};