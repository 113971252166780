import { Button, Popper, Popover, Box, Stack, Typography, PopoverOrigin } from '@mui/material';
import React, { useRef, useState } from 'react';

export type Lines3DLegendProps = {
    opened: boolean
    onClose?: () => void
    anchorOrigin?: PopoverOrigin,
    anchor: HTMLElement | null
};

export const Lines3DLegend = ({ opened, onClose = () => {}, anchorOrigin = { vertical: 'bottom', horizontal: 'left' }, anchor } : Lines3DLegendProps) => {

    return (
        <React.Fragment>
            <Popover
                anchorEl={anchor}
                open={opened}
                onClose={onClose}
                sx={{zIndex: 999999, top: '-100px', left: '-50px'}}
                anchorOrigin={anchorOrigin}
            >
                <Stack padding={'20px'} gap={3} sx={{backgroundColor: 'white', zIndex: 999999, width: '300px'}}>
                    <Stack direction='row' gap={2}>
                        <Box width={15} height={15} sx={{border: '1px solid black', backgroundColor: '#66ff66'}}/>
                        <Typography>Loaded line</Typography>
                    </Stack>
                    <Stack direction='row' gap={2}>
                        <Box width={15} height={15} sx={{border: '1px solid black', backgroundColor: '#ffff66'}}/>
                        <Typography>Loading line</Typography>
                    </Stack>
                    <Stack direction='row' gap={2}>
                        <Box width={15} height={15} sx={{border: '1px solid black', backgroundColor: 'white'}}/>
                        <Typography>Not loaded line</Typography>
                    </Stack>
                    <Stack>
                        <Typography>
                            Transparent tiles in a seismic means that this part is not yet loaded.
                        </Typography>
                    </Stack>
                </Stack>
            </Popover>
        </React.Fragment>
    );
};