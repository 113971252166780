import Layer from 'ol/layer/Tile';
import Source from 'ol/source/TileImage';
import TileGrid from 'ol/tilegrid/TileGrid';
import { ImageTile, Tile, TileCache } from 'ol';
import { getRenderPixel } from 'ol/render';
import TileState from 'ol/TileState';
import { TileCoord } from 'ol/tilecoord';
import { Size, toSize } from 'ol/size';

import { LineType } from '../enums/LineType';
import { IGeopostSeismicLayerParams } from '../interfaces/IGeopostSeismicLayerParams';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { GeopostMap } from './GeopostMap';
import { TileSourceStrategy } from './TileSourceStrategies/TileSourceStrategy';

export class GeopostSeismicLayer extends Layer<Source> {
    protected Source?: Source;
    Self?: GeopostSeismicLayer;

    protected _cacheTileSystem:{[url: string]:string} = {};
    protected _httpRequest:{
        ajax: XMLHttpRequest,
        tile: ImageTile
    }[] = [];

    protected objectZoomTestControler:{[zoom: number]: {
        lineStart: string,
        lineEnd: string,
        lineStartSample: string,
        lineEndSample: string,
        url: string
    }[]} = {};

    protected objectIgnoredTestControler:{[zoom: number]: {
        lineStart: string,
        lineEnd: string,
        lineStartSample: string,
        lineEndSample: string,
        url: string
    }[]} = {};

    protected count = 0;

    protected Params: IGeopostSeismicLayerParams = {
        accessToken: '',
        tenantToken: '',
        //gain: 0,
        absoluteGain: {
            min: -100,
            max: 100,
        },
        debug: false,
        resolutions: [],
        tileDimensionsPerResolution: [],
        extent: [0, 0, 0, 0],
        tileWidth: 0,
        tileHeight: 0,
        volumeToken: '',
        urlRender: '',
        filePath: '',
        colorbar: '',
        skipTraceCount: 1,
        lineNumber: 1,
        byteSize: 1,
        parallelProcesses: 1,
        dpi: 1,
        minSampleValue: 1,
        maxSampleValue: 1,
        jpgQuality: 1,
        isIndexedVolume: false,
        scaleX: 0,
        scaleY: 0,
        traceRangeStart: 0,
        traceRangeEnd: 0,
        isSwiped: false,
        swipeValue: 50,
        geopostMap: undefined,
        sampleInterval: 0,
        applyWatermark: false,
        inlineDimensionName: 'inline',
        xlineDimensionName: 'xline',
        sampleDimensionName: 'sample',
        loadingLayer: undefined,
        loadImageDebounce: 25,
        renderLimits: [],
        volumeSamplesPerTrace: 0,
        numberOfTraces: 0,
        verticallyInverted: false,
        lineType: LineType.Inline,
        prepareRenderChunkSize: 128,
    };
    protected minSampleValue = 0;
    protected maxSampleValue = 0;
    protected timeDebugging: boolean;
    protected prepareLoadingKeys: string[] = [];
    public tileSourceStrategy: TileSourceStrategy;
    map?: GeopostMap;

    private durationLastTimeOutCheck = 20000;
    private lastTimeOutRenderCheck?: NodeJS.Timeout;

    //dictionariesRetries:{[key:string]:number} = {};

    tileGrid: TileGrid | null = null;

    constructor(params: IGeopostSeismicLayerParams, tileSourceStrategy: TileSourceStrategy, timeDebugging: boolean = false) {
        super({
            preload: 0,
            visible: true
        });
        this.tileSourceStrategy = tileSourceStrategy;
        this.updateParams(params);

        this.buildSource();
        this.setSwipeEvents();
        this.timeDebugging = timeDebugging;

        /*setTimeout(() => {
            console.log('trocando');
            this.Params.accessToken = 'adas';
        }, 10000);*/

        /*setInterval(() => {
            //const array = this._httpRequest.map(x=>x.ajax.status).filter(x=>x !== 200 && x !== 401 && x !== 0);
            if (this._httpRequest.length === 0){
                return;
            }
            const array = this._httpRequest.map(x=>x.ajax.readyState).filter(x=>x !== 4);

            if (array.length === 0){
                console.log(this._httpRequest.map(x=>x.ajax.readyState));
                this._httpRequest = [];
                this.clearLoadingBarriers();
                console.log('limpand loadings');
            }
        }, 20000); */
    }

    rebuildSource() {
        this.buildSource();
    }

    protected buildGrid() {
        console.log(this.Params.resolutions);
        console.log(this.Params.tileDimensionsPerResolution);
        return new TileGrid({
            extent: this.Params.extent!,
            minZoom: 0,
            resolutions: this.Params.resolutions!,
            //tileSize: [this.Params.tileWidth!, this.Params.tileHeight!],
            tileSizes: this.Params.tileDimensionsPerResolution!
        });
    }

    protected buildSource() {
        const tileGrid = this.buildGrid();
        const source = new Source({
            cacheSize: 0,
            interpolate: true,
            tileGrid: tileGrid,
            tilePixelRatio: 1,
            transition: 200,
            zDirection: 0
        });

        this.tileGrid = tileGrid;

        this.setSource(source);
        this.Source = source;

        this.setTileUrlFunction();
        this.setTileLoadFunction();
    }

    protected finishTileLoad(fullUrl: string) {
        const key = this.getTileLoadingKey(fullUrl);
        if (this.Params.loadingLayer){
            this.Params.loadingLayer?.hideLoading(key);
        }

        this.Params.increaseLoaded!(fullUrl);
    }

    setSwipeEvents() {
        this.on('prerender', (event) => {
            const ctx = event.context as CanvasRenderingContext2D;
            if (this.Params.geopostMap && this.Params.swipeValue) {
                const mapSize = this.Params.geopostMap.getSize();
                if (mapSize) {
                    const width = mapSize[0] * (this.Params.swipeValue / 100);
                    const tl = getRenderPixel(event, [width, 0]);
                    const tr = getRenderPixel(event, [mapSize[0], 0]);
                    const bl = getRenderPixel(event, [width, mapSize[1]]);
                    const br = getRenderPixel(event, mapSize);
                    if (ctx) {
                        ctx.save();
                        if (this.Params.isSwiped) {
                            ctx.beginPath();
                            ctx.moveTo(tl[0], tl[1]);
                            ctx.lineTo(bl[0], bl[1]);
                            ctx.lineTo(br[0], br[1]);
                            ctx.lineTo(tr[0], tr[1]);
                            ctx.closePath();
                            ctx.clip();
                        }
                    }
                }
            }
        });

        this.on('postrender', function (event) {
            const ctx = event.context as CanvasRenderingContext2D;
            ctx.restore();
        });
    }

    stopRequests(ignoreByZoom = -1){
        if (this.Params.resetLoadingControl){
            this.Params.resetLoadingControl();
        }

        this._httpRequest.forEach(item => {
            item.tile.setState(TileState.LOADED); //LOADED
            item.ajax.abort();
        });

        if (this.Params.loadingLayer){
            this.Params.loadingLayer.clearAllLoading();
        }
        this._httpRequest = [];
    }

    updateParams(params: IGeopostSeismicLayerParams, resetPreviousRender: boolean = true) {
        //console.log('update params');
        this.Params = Object.assign(this.Params, params);

        this.updateGain();
        if (resetPreviousRender) {
            //this.stopRequests();
        }

        return this;
    }

    updateGain() {
        this.minSampleValue = this.Params.absoluteGain?.min ?? 0;
        this.maxSampleValue = this.Params.absoluteGain?.max ?? 0;
    }

    protected setTileUrlFunction() {
        if (!this.Source) {
            return;
        }
        this.Source.setTileUrlFunction(this.tileUrlFunction);

    }

    public clearLoadingBarriers = () => {
        this.Params.loadingLayer?.clearAllLoading();
        this.Params.resetLoadingControl!();
    };

    protected tileUrlFunction = () => {
        return this.tileSourceStrategy.getTileUrl(this.Params);
    };

    private addParamToUrl(param: string) {
        return `&${param}=[${param}]`;
    }

    private addParamValueToUrl(param: string, value: string) {
        return `&${param}=${value}`;
    }

    getTileCoordKey(tileCoord: TileCoord){
        return `${tileCoord[0]}-${tileCoord[1]}-${tileCoord[2]}`;
    }

    reload() {
        //reload é pra evitar o blink
        setTimeout(() => {

            const view = this.map!.getView()!;
            const zoom = view.getZoom()!;
            const extent = view.calculateExtent(this.map!.getSize());
            const objectTileGridToIgnore: {[key:string]: boolean} = {};
            const source = this.getSource()!;

            let tiles: ImageTile[] = [];
            if (this.Params.lineType !== LineType.ZSlice) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                tiles = (this.getRenderer() as any).renderedTiles as ImageTile[];
            }
            else {
                source.getTileGrid()!.forEachTileCoord(extent, zoom, (tileCoordinate) => {
                    const tile = source.getTile(tileCoordinate[0], tileCoordinate[1], tileCoordinate[2], window.devicePixelRatio, view.getProjection()) as ImageTile;
                    tiles.push(tile);
                });
            }

            console.log('tiles ------>', tiles);

            this.getSource()!.getTileGrid()!.forEachTileCoord(extent, zoom, (a) => {
                objectTileGridToIgnore[this.getTileCoordKey(a)] = true;
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tileCache = (this.getSource() as any).tileCache as TileCache;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const entries = (tileCache as any).entries_ as {[value_:string]:EntryCache};

            for (let e in entries){
                const entry = entries[e];
                const tile = entry.value_;
                const key = this.getTileCoordKey(tile.getTileCoord());

                if (objectTileGridToIgnore[key]){
                }
                else {
                    tileCache.remove(e);
                }
            }

            //tileCache.ent
            //tileCache.clear();
            //linha 290 - getTile
            //getTileInternal
            //getTileCacheForProjection

            for (let tile of tiles) {

                const url = this.tileUrlFunction();
                this.tileLoadFunction(tile, url);
            }
        }, 50);
    }

    protected setTileLoadFunction() {
        if (!this.Source) {
            return;
        }

        this.Source.setTileLoadFunction(this.tileLoadFunction);
    }

    protected getTileLoadingKey = (fullUrl: string) => {
        return this.tileSourceStrategy.getTileLoadingKey(fullUrl);
    };

    protected extractUrlValue = (url: string, key: string) =>
    {
        const match = url.match('[?&]' + key + '=([^&]+)');
        return match ? match[1] : null;
    };

    protected getTileSize() {
        return toSize((this.tileGrid?.getTileSize(this.map?.getView().getConstrainedZoom(this.map?.getView().getZoom(), 0) ?? 0) ?? [ this.Params.tileWidth!, this.Params.tileHeight!]));
    }

    protected getFullUrl = (src: string, xStart: number, xEnd: number, yStart: number, yEnd: number, minGain: number, maxGain: number) => {
        const tileSize = this.getTileSize();
        return this.tileSourceStrategy.getFullUrl(src, xStart, xEnd, yStart, yEnd, minGain, maxGain).replace('[tileWidth]', tileSize[0].toString()).replace('[tileHeight]', tileSize[1].toString());
    };

    protected tileLoadFunction = (tile: Tile, src: string) => {
        const [ surveyLineStart, surveyLineEnd, surveyLineIncrement ] = this.tileSourceStrategy.getSurveyLineDimensions(this.Params);

        const tileSize = this.getTileSize();
        const axisXTraceFactor = tileSize[0]! * this.Params.resolutions![tile.tileCoord[0]] / this.Params.scaleX! * surveyLineIncrement;

        const startTrace = this.Params.extent![0] + (tile.tileCoord[1] * axisXTraceFactor);
        let endTrace = this.Params.extent![0] + ((tile.tileCoord[1] + 1) * axisXTraceFactor);

        const pixelPerSample = parseInt((tileSize[1]! * this.Params.resolutions![tile.tileCoord[0]] / this.Params.scaleY!).toString());
        const startAmplitude = (this.Params.extent![3] - tile.tileCoord[2] * pixelPerSample) * -1;
        const endAmplitude = (this.Params.extent![3] - ((tile.tileCoord[2] + 1) * pixelPerSample)) * -1;

        let  minSample:number = this.minSampleValue;
        let  maxSample:number = this.maxSampleValue;

        minSample = this.Params.absoluteGain?.min ?? -100;
        maxSample = this.Params.absoluteGain?.max ?? 100;

        const mapExtent = this.map?.getView().calculateExtent(this.map.getSize());

        const fullUrl = this.getFullUrl(src, startTrace, endTrace, startAmplitude, endAmplitude, minSample, maxSample);

        const tileLoadingKey = this.getTileLoadingKey(fullUrl);

        const totalTraces = (surveyLineEnd - surveyLineStart) / surveyLineIncrement;
        const prepareRenderStep = getParamsFromUrl().prepareRenderStep;
        const totalPrepareChunks = Math.ceil(totalTraces / (prepareRenderStep * this.Params.prepareRenderChunkSize!));

        const limits = this.Params.renderLimits!;

        //verifica se o prepareRender ainda não preparou todos os tiles
        /*console.log(`comparison ${limits.length} x ${totalPrepareChunks}`);
        if (limits.length > totalPrepareChunks) {
            console.log('a');
        }*/

        if (this.Params.lineType === LineType.ZSlice && tile.getState() === TileState.EMPTY) {
            tile.load();
            this.Params.increaseLoading!(fullUrl);
            return;
        }

        if (limits.length < totalPrepareChunks) {
            //verifica interseção com os limites de renderização definidos
            let intersectionRange = 0;
            for (let i = 0; i < limits.length; i++) {
                const limit = limits[i];
                let lowerLimit = limit[0];
                let upperLimit = limit[1];
                if (startTrace > upperLimit || lowerLimit > endTrace) {
                    continue;
                }
                intersectionRange += Math.min(upperLimit, endTrace) - Math.max(lowerLimit, startTrace);
            }

            if (intersectionRange < (endTrace - startTrace)) {
                tile.setState(TileState.LOADED);
                this.Params.increaseLoading!(fullUrl);
                return;
            }
        }

        const zoom = this.map?.getView().getConstrainedZoom(this.map?.getView().getZoom(), 0) ?? 0;

        const cachedTileEntry = this._cacheTileSystem[fullUrl];

        const hasCache = (!!cachedTileEntry);

        if (this.lastTimeOutRenderCheck){
            clearInterval(this.lastTimeOutRenderCheck);
        }

        console.log('iniciando novo timeout de check');

        this.lastTimeOutRenderCheck = setInterval(() => {
            console.log('exectando timeoutde check');
            //const array = this._httpRequest.map(x=>x.ajax.status).filter(x=>x !== 200 && x !== 401 && x !== 0);
            if (this._httpRequest.length === 0){
                return;
            }
            const array = this._httpRequest.map(x=>x.ajax.readyState).filter(x=>x !== 4);
            if (array.length === 0){
                this._httpRequest = [];
                //this.clearLoadingBarriers();
                console.log('limpand loadings');
                clearInterval(this.lastTimeOutRenderCheck);
            }
        }, this.durationLastTimeOutCheck);

        setTimeout(() => {
            const currentZoom = this.map?.getView().getConstrainedZoom(this.map?.getView().getZoom(), 0) ?? 0;
            if (currentZoom === zoom){
                //console.log('Carregando tile');

                const [ surveyLineStart, surveyLineEnd, surveyLineIncrement ] = this.tileSourceStrategy.getSurveyLineDimensions(this.Params);

                const currentResolution = this.map?.getView().getResolution();
                const loadingCircleRadius = (!!currentResolution && currentResolution) > 5 ? 5 : 15;

                this.Params.loadingLayer?.showLoading(tileLoadingKey, startTrace, endTrace, surveyLineIncrement, surveyLineStart, this.Params.scaleX!, startAmplitude * -1, endAmplitude * -1,  currentZoom, mapExtent, undefined, loadingCircleRadius);

                this.loadImage(fullUrl, tile as ImageTile, 0);
            }
            else {
                tile.setState(TileState.ERROR);
                //console.log('Descartando tile');
            }
        }, hasCache ? 5 : this.Params.loadImageDebounce);
    };

    protected setImageOnTile(tile: ImageTile, urlData: string){
        (tile.getImage() as HTMLImageElement).src = urlData;

        tile.setState(TileState.LOADED);

        setTimeout(() => {
            this.getSource()?.changed();
        }, 50);
    }

    protected loadImage(fullUrl: string, tile: ImageTile, retry: number) {
        this.Params.increaseLoading!(fullUrl);
        const cachedTileEntry = this._cacheTileSystem[fullUrl];

        /*if (!this.tileToError){
            this.tileToError = fullUrl;
        }*/

        if (!!cachedTileEntry){
            if (cachedTileEntry === 'loading') {
                return;
            }
            this.setImageOnTile(tile, this._cacheTileSystem[fullUrl]);
            this.finishTileLoad(fullUrl);
            return;
        }

        this.count++;
        //console.log(this.count);

        this._cacheTileSystem[fullUrl] = 'loading';
        const xhr = new XMLHttpRequest();

        this._httpRequest.push({
            ajax: xhr,
            tile: tile
        });

        //let paramsPost =  (this.tileToError === fullUrl) ? 'https://asasasasa.comansa':fullUrl.replace(this.Params.urlRender + '?', '');
        let paramsPost = fullUrl.replace(this.Params.urlRender + '?', '');
        xhr.responseType = 'blob';
        const requestSendAt = new Date();

        const handleSuccess = () => {
            const data = xhr.response;
            if (data !== null) {
                if (data !== undefined) {
                    const urlData = URL.createObjectURL(data);
                    this._cacheTileSystem[fullUrl] = urlData;

                    this.setImageOnTile(tile, urlData);
                    this.finishTileLoad(fullUrl);
                    (tile.getImage() as HTMLImageElement).src = urlData;

                    this.tileSourceStrategy.onSettleTileRequest(xhr, this.Params.lineNumber!, this.Params.tileWidth!, requestSendAt);
                } else {
                    tile.setState(TileState.ERROR);
                    this.finishTileLoad(fullUrl);
                }

            } else {
                tile.setState(TileState.ERROR);
                this.finishTileLoad(fullUrl);
            }

        };

        const reCall = () => {
            //console.log('retrying tile ' + tile.tileCoord + ': ' + retry + 1);
            delete this._cacheTileSystem[fullUrl];
            this.loadImage(fullUrl, tile, retry + 1);
        };

        const handleError = () => {
            //console.log('error on load tile ' + tile.tileCoord + ': ' + retry);
            /*if (!this.dictionariesRetries[tile.tileCoord.toString()]){
                this.dictionariesRetries[tile.tileCoord.toString()] = 0;
            }
            this.dictionariesRetries[tile.tileCoord.toString()]++;
            */
            //console.log(this.dictionariesRetries);

            if (retry <= 3){
                reCall();
            } else {
                const tileSize = this.getTileSize();
                const lineIncrement = this.tileSourceStrategy.getSurveyLineDimensions(this.Params)[2];
                const axisXTraceFactor = tileSize[0]! * this.Params.resolutions![tile.tileCoord[0]] / this.Params.scaleX! * lineIncrement;
                const startTrace = this.Params.extent![0] + (tile.tileCoord[1] * axisXTraceFactor);
                let endTrace = this.Params.extent![0] + ((tile.tileCoord[1] + 1) * axisXTraceFactor);
                this.tileSourceStrategy.onTileRequestError(xhr, this.Params.lineNumber!, this.Params.tileWidth!, startTrace, endTrace);

                //this.setImageOnTile(tile, '/picture/tile-error.png');

                tile.setState(TileState.ERROR);
                this.finishTileLoad(fullUrl);
            }
        };

        xhr.onreadystatechange = (ev) => {
            if (xhr.readyState === 4) {
                if (xhr.status === 0) {
                    handleError();
                }
                else if (xhr.status < 300) {
                    handleSuccess();
                } else {
                    handleError();
                }
            }
        };

        if (this.Params.debug) {
            xhr.open('POST', `${this.tileSourceStrategy.getEndpoint()}?z=${tile.tileCoord[0]}&x=${tile.tileCoord[1]}&y=${tile.tileCoord[2]}`);
        }
        else {
            xhr.open('POST',  this.tileSourceStrategy.getEndpoint());
        }

        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        //xhr.setRequestHeader('use-europe-endpoint', 'true');
        xhr.setRequestHeader('isGeopost', 'true');
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.Params.accessToken);
        xhr.setRequestHeader('external_sso_access_token', 'Bearer ' + getParamsFromUrl().externalSSOAccessToken);
        xhr.setRequestHeader('x-gp-debug-active', this.timeDebugging.toString());

        // if (!myLocalCache[paramsPost])

        //paramsPost += '&zoom=' + zoom;

        const delayTileLoad = getParamsFromUrl().delayTileLoad;
        if (delayTileLoad > 0) {
            setTimeout(() => {
                xhr.send(paramsPost);
            }, delayTileLoad);
        } else {
            xhr.send(paramsPost);
        }
    }
    getParams(){
        return this.Params;
    }
    getSampleValues(){
        return {minSampleValue: this.minSampleValue , maxSampleValue: this.maxSampleValue};
    }
    setTileSourceStrategy(strategy: TileSourceStrategy){
        this.tileSourceStrategy = strategy;
        this.rebuildSource();
    }
}

interface EntryCache {
    value_: ImageTile;
}