import WKT from 'ol/format/WKT';
import { LineString } from 'ol/geom';
import { useCallback, useRef } from 'react';

import { useGetLineGeometryImperatively } from 'features/seismic-3d/api/useSeismic3DInfoController';
import { LineType } from 'features/seismic-3d/models/enums/LineType';
import { VolumeType } from 'features/seismic-3d/models/enums/VolumeType';
import { useSeismicStore } from '../stores/useSeismicStore';
import { SeismicCalculatorFactory } from '../models/classes/SeismicCalculatorFactory';
import { getXByTrace, getYByAmplitude } from '../utils/calculateScreenValuesUtils';
import { LineStringResponse } from 'features/seismic-3d/models/types/LineStringResponse';
import { ISurveyMetadata } from '../models/interfaces/ISurveyMetadata';
import { getParamsFromUrl } from '../utils/seismicUrlUtils';

export const useGetSliceRotation = () => {
    const srid = 4326;

    const [scaleY, scaleX] = useSeismicStore(state => [ state.scale.x, state.scale.y ]);

    const { mutateAsync: getLineGeometry } = useGetLineGeometryImperatively();

    const { current: forceDisableImageCache } = useRef(getParamsFromUrl().forceDisableImageCache);

    const referenceInlineDataRef = useRef<LineStringResponse>();
    const referenceXlineDataRef = useRef<LineStringResponse>();

    const getSliceRotation = useCallback(async (lineType: LineType, surveyMetadata: ISurveyMetadata | undefined | null) => {
        if (!!surveyMetadata && lineType === LineType.ZSlice) {
            const referenceInlineNumber = SeismicCalculatorFactory.buildCalculator(surveyMetadata.IsIndexed, LineType.Inline, surveyMetadata.Type).calculateLineStart(surveyMetadata);
            const referenceXlineNumber = SeismicCalculatorFactory.buildCalculator(surveyMetadata.IsIndexed, LineType.Xline, surveyMetadata.Type).calculateLineStart(surveyMetadata);

            if (!(!!referenceInlineDataRef.current && !!referenceXlineDataRef.current)) {
                const referenceXlineData = await getLineGeometry({
                    lineNumber: referenceXlineNumber,
                    lineType: LineType.Xline,
                    srid: srid,
                    volumeToken: surveyMetadata.VolumeToken,
                    volumeType: surveyMetadata.Type as number as VolumeType,
                    dimensionName: surveyMetadata.DimensionNames.Xline,
                    forceDisableCache: forceDisableImageCache,
                });

                const referenceInlineData = await getLineGeometry({
                    lineNumber: referenceInlineNumber,
                    lineType: LineType.Inline,
                    srid: srid,
                    volumeToken: surveyMetadata.VolumeToken,
                    volumeType: surveyMetadata.Type as number as VolumeType,
                    dimensionName: surveyMetadata.DimensionNames.Inline,
                    forceDisableCache: forceDisableImageCache,
                });
                referenceInlineDataRef.current = referenceInlineData;
                referenceXlineDataRef.current = referenceXlineData;
            }

            const referenceInlineData = referenceInlineDataRef.current!;
            const referenceXlineData = referenceXlineDataRef.current!;

            const wktReader = new WKT();

            const xlineGeometry = wktReader.readGeometry(referenceXlineData.Line) as LineString;
            const xlineFirstCoord = xlineGeometry.getFirstCoordinate();
            const xlineLastCoord = xlineGeometry.getLastCoordinate();

            //Descobrindo a rotação entre os dois pontos
            const radsRotation = Math.atan2(xlineLastCoord[1] - xlineFirstCoord[1], xlineLastCoord[0] - xlineFirstCoord[0]);

            const inlineGeometry = wktReader.readGeometry(referenceInlineData.Line) as LineString;
            const inlineFirstCoord = inlineGeometry.getFirstCoordinate();
            const inlineLastCoord = inlineGeometry.getLastCoordinate();

            //Descobre se o time slice está de cabeça para baixo por padrão, caso esteja o offset "desvira" ele
            const needsVerticalFlip = inlineFirstCoord[1] < inlineLastCoord[1] ?
                true
                :
                inlineFirstCoord[1] === inlineLastCoord[1] ?
                    xlineFirstCoord[1] < xlineLastCoord[1] ?
                        true
                        :
                        false
                    :
                    false;

            //Descobre se o time slice veio flipado na horizontal, caso tenha vindo, precisa ser flipado novamente para "desflipar"
            const needsFlip = xlineFirstCoord[0] > xlineLastCoord[0] ?
                true
                :
                xlineFirstCoord[0] === xlineLastCoord[0]?
                    inlineFirstCoord[0] > inlineLastCoord[0]?
                        true
                        :
                        false
                    :
                    false;

            const anchorX = getXByTrace(referenceInlineData.Min, scaleX, surveyMetadata.Survey3DInfo.InlineStart, surveyMetadata.Survey3DInfo.InlineIncrement);
            const minXline = surveyMetadata.Survey3DInfo.XlineStart;
            const anchorY = getYByAmplitude(referenceXlineData.Min - minXline, scaleY, surveyMetadata.Survey3DInfo.XlineIncrement);
            const fullRotation = -1 * (radsRotation);
            //map.getView().setCenter([mapCenter[0], mapCenter[1] + Math.abs(anchorY - maxY)]);
            return { needsFlip: needsFlip, rotation: fullRotation, reference: [anchorX, anchorY], needsVerticalFlip: needsVerticalFlip };
        } else {
            return { needsFlip: false, rotation: 0, reference: [0, 0], needsVerticalFlip: false };
        }
    }, [getLineGeometry, scaleX, scaleY]);

    return getSliceRotation;
};