
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { CacheSystem } from 'features/seismic/models/enums/CacheSystem';
import { useGetZSliceTile } from '../api/useSeismic3DInfoController';
import { DimensionIndex, Dimension3D } from './Dimension3D';
import { LineType } from '../models/enums/LineType';
import { Metadata3D } from '../models/types/Metadata3D';
import { Survey3DInfo } from '../models/types/Survey3DInfo';
import { ZSliceTileRequest } from '../models/types/ZSliceTileRequest';
import { use3DGridStore } from '../stores/use3DGridStore';
import { use3DSceneStore } from '../stores/use3DSceneStore';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';

export type Zslice3DProps = {
    surveyMetadata: Metadata3D,
    sliceNumbersToLoad: number[],
    onTotalSlicesLoadedChange: (total: number) => void,
    colorbar: string,
    isVisible: boolean,
};

export const Zslice3D = ({ surveyMetadata, sliceNumbersToLoad, onTotalSlicesLoadedChange, colorbar, isVisible } : Zslice3DProps) => {
    const totalLoadedSlicesRef = useRef(1);
    const zsliceNumbers = use3DGridStore(state => state.totalZsliceNumbers);
    const setZsliceNumbers = use3DGridStore(state => state.setTotalZsliceNumbers);

    const searchedZsliceNumber = use3DGridStore(state => state.searchedZsliceNumber);
    const setSelectedZsliceNumber = use3DGridStore(state => state.setSelectedZSliceNumber);
    const setIsZsliceSearchLoading= use3DGridStore(state => state.setIsZsliceSearchLoading);

    const survey3DInfo = surveyMetadata.Survey3DInfo;
    const surveyHeader = surveyMetadata.Header;
    const entitlementLimits = surveyMetadata.Entitlement3DLimits;

    const geopostScene = use3DSceneStore(state => state.scene);

    const { current: createdDepthNumbers } = useRef<number[]>([]);

    const [tileHeight, tileWidth] = [350, 350];

    const tracesXPixelsFactor = useMemo(() => {
        const divisionFactor = 6000;
        const factor = Math.round((survey3DInfo.TotalInlines + survey3DInfo.TotalXlines) / divisionFactor);
        return factor > 0 ? factor : 1;
    }, [survey3DInfo]);

    const [ tracesTileWidth, tracesTileHeight ] = useMemo(() => {
        return [ tileWidth * tracesXPixelsFactor, tileHeight * tracesXPixelsFactor];
    }, [tileWidth, tileHeight, tracesXPixelsFactor]);

    const [ samplesPerTrace, sampleInterval, byteSize ] = [
        surveyHeader?.SamplesPerTrace ?? 0,
        surveyHeader?.SampleInterval ?? 0,
        surveyHeader?.ByteSize ?? 0
    ];

    const [ inlineStart, inlineEnd, inlineIncrement ] = useMemo(() => [survey3DInfo.InlineStart, survey3DInfo.InlineEnd, survey3DInfo.InlineIncrement], [survey3DInfo.InlineEnd, survey3DInfo.InlineStart, survey3DInfo.InlineIncrement]);
    const [ xlineStart, xlineEnd, xlineIncrement ] = useMemo(() => [survey3DInfo.XlineStart, survey3DInfo.XlineEnd, survey3DInfo.XlineIncrement], [survey3DInfo.XlineEnd, survey3DInfo.XlineStart, survey3DInfo.XlineIncrement]);

    const [ priorityDimensionIndex, setPriorityDimensionIndex ] = useState<DimensionIndex | null>(null);
    const [ dimensionIndexesToLoad, setDimensionIndexesToLoad ] = useState<DimensionIndex[]>([]);

    const [ selectedNumber, setSelectedNumber ] = useState<number | null>(null);

    const { mutateAsync: getZsliceTile } = useGetZSliceTile(SurveyType.Seismic3D);

    const seismic3DDefaults = useSessionStore(state => state.tenantConfiguration?.viewer3DDefaults);

    const prepareRenderChunkSize = useSessionStore(state => state.tenantConfiguration?.prepareRenderChunkSize);

    const loadTile = async (depthNumber: number, tileInlineStart: number, tileInlineEnd: number, tileXlineStart: number, tileXlineEnd: number) => {

        const params: ZSliceTileRequest = {
            sampleInterval,
            byteSize,
            surveyInlineStart: survey3DInfo.InlineStart,
            surveyInlineEnd: survey3DInfo.InlineEnd,
            surveyInlineIncrement: survey3DInfo.InlineIncrement,
            surveyXlineStart: survey3DInfo.XlineStart,
            surveyXlineEnd: survey3DInfo.XlineEnd,
            surveyXlineIncrement: survey3DInfo.XlineIncrement,
            minSampleValue: surveyMetadata.MinSampleValue,
            maxSampleValue: surveyMetadata.MaxSampleValue,
            applyWatermark: false,
            colorbar: colorbar,
            dpi: 1,
            filePath: surveyMetadata.VolumePath,
            volumeToken: surveyMetadata.VolumeToken,
            forceDisableCache: getUrlParams().forceDisableCache,
            forceDisableSamplesCache: false,
            imageCacheSystem: CacheSystem.CloudStorage,
            inverted: false,
            jpgQuality: 70,
            samplesCacheSystem: CacheSystem.CloudStorage,
            parallelProcesses: 10,
            skipTraceCount: getUrlParams().traceDecimation,
            tileHeight,
            tileWidth,
            render: getUrlParams().sourceApi,
            traceRangeEnd: 0,
            traceRangeStart:0,
            inlineStart: tileInlineStart * inlineIncrement + entitlementLimits.InlineStart,
            inlineEnd: tileInlineEnd * inlineIncrement + entitlementLimits.InlineStart,
            xlineStart: tileXlineStart * xlineIncrement + entitlementLimits.XlineStart,
            xlineEnd: tileXlineEnd * xlineIncrement + entitlementLimits.XlineStart,
            depthNumber,
            isIndexedVolume: true,
            inlineDimensionName: surveyMetadata.DimensionNames.Inline,
            xlineDimensionName: surveyMetadata.DimensionNames.Xline,
            sampleDimensionName: surveyMetadata.DimensionNames.Sample,
            verticallyInverted: false,
            prepareRenderChunkSize: (prepareRenderChunkSize ?? 128),
        };

        return await getZsliceTile(params);
    };

    const onLoadTile = useCallback(
        (tile: Blob, lineNumber: number, tileInlineStart: number, tileLineEnd: number, tileXlineStart: number, tileSampleEnd: number) =>
            geopostScene?.addTileTexture(tileInlineStart, tileXlineStart, lineNumber, LineType.ZSlice, tile)
        , [geopostScene]
    );

    const onLoadSlice = useCallback((sliceNumber: number) => {
        if (sliceNumber === searchedZsliceNumber) {
            setIsZsliceSearchLoading(false);
        }
        totalLoadedSlicesRef.current += 1;
        console.log('TOTAL LOADED SLICES ==========>', totalLoadedSlicesRef);
        onTotalSlicesLoadedChange(totalLoadedSlicesRef.current);
    }, [onTotalSlicesLoadedChange, searchedZsliceNumber, setIsZsliceSearchLoading]);

    const totalInlines = useMemo(() => entitlementLimits.TotalInlines, [entitlementLimits.TotalInlines]);
    const totalXlines = useMemo(() => entitlementLimits.TotalXlines, [entitlementLimits.TotalXlines]);

    useEffect(() => {
        const newDepthNumbersToLoad = sliceNumbersToLoad.filter(depthNumber => !createdDepthNumbers.includes(depthNumber));
        createdDepthNumbers.push(...newDepthNumbersToLoad);
        newDepthNumbersToLoad.forEach(depthNumber => {
            geopostScene?.addZSliceTilesByDepth(totalInlines,totalXlines, (entitlementLimits.InlineStart - inlineStart) / inlineIncrement, (entitlementLimits.XlineStart - xlineStart) / xlineIncrement, depthNumber, sampleInterval, tracesTileWidth, tracesTileHeight);
        });

        setDimensionIndexesToLoad(sliceNumbersToLoad.map(depthNumber => ({
            indexNumber: depthNumber,
            totalX: totalInlines,
            totalY: totalXlines
        })));
        //onAddTiles();
    }, [sliceNumbersToLoad]);

    useEffect(() => {
        if (!!searchedZsliceNumber || searchedZsliceNumber === 0) {
            if (!dimensionIndexesToLoad.find(x => x.indexNumber === searchedZsliceNumber) && priorityDimensionIndex?.indexNumber !== searchedZsliceNumber && !sliceNumbersToLoad.includes(searchedZsliceNumber)) {
                setIsZsliceSearchLoading(true);
                geopostScene?.addZSliceTilesByDepth(totalInlines, totalXlines, (entitlementLimits.InlineStart - inlineStart) / inlineIncrement, (entitlementLimits.XlineStart - xlineStart) / xlineIncrement, searchedZsliceNumber, sampleInterval, tracesTileWidth, tracesTileHeight);
                setPriorityDimensionIndex({ indexNumber: searchedZsliceNumber, totalX: totalInlines, totalY: totalXlines });
                const newZsliceNumbers = [...zsliceNumbers, searchedZsliceNumber];
                newZsliceNumbers.sort((a, b) => a - b);
                setZsliceNumbers(newZsliceNumbers);
            }
            setSelectedNumber(searchedZsliceNumber);
        }
    }, [searchedZsliceNumber, geopostScene]);

    return (
        <Dimension3D
            onIndexLoading={() => {}}
            isVisible = { isVisible }
            indexesToLoad={dimensionIndexesToLoad}
            lineType={LineType.ZSlice}
            loadTile={loadTile}
            onLoadIndex={onLoadSlice}
            onLoadTile={onLoadTile}
            onSelectIndex={setSelectedZsliceNumber}
            selectedIndexNumber={selectedNumber}
            priorityIndexToLoad={priorityDimensionIndex}
            tileHeight={tracesTileHeight}
            tileWidth={tracesTileWidth}
        />
    );
};